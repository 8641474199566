import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  displayUpdateNews,
  availableReleaseNotes,
  setLocalStorageReleaseNotesRead,
} from '../../utils/ReleaseNotesUtils';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 600,
      height: '100vh',
      padding: 16,
      '& .MuiDialogTitle-root': {
        paddingBottom: 4,
        '& .MuiTypography-root': {
          fontWeight: 700,
          fontSize: '1.875rem',
        },
      },
      '& .MuiDialogContent-root': {
        '&::-webkit-scrollbar': {
          width: 4,
        },
        '&::-webkit-scrollbar-thumb': {
          background: theme.palette.primary.light,
        },
      },
      '@media (max-width:991px)': {
        padding: 12,
        margin: 24,
      },
      '@media (max-width:599px)': {
        padding: 6,
        margin: 12,
        '& .MuiDialogTitle-root': {
          padding: '8px 12px',
        },
      },
    },
  },
  intro: {
    fontSize: '1.4rem',
    fontWeight: 700,
    lineHeight: 1.2,
    color: theme.palette.grey.A700,
  },
  content: {
    marginTop: 4,
    '& ul > li': {
      fontSize: '1.25rem',
      marginBottom: 8,
      lineHeight: 1.25,
      '& span:nth-child(1)': {
        fontSize: '1.5rem',
        fontWeight: 700,
        color: theme.palette.primary.main,
      },
      '& span:nth-child(2)': {
        fontSize: '0.875em',
        color: theme.palette.grey.A700,
        marginLeft: 4,
      },
    },
  },
  button: {
    padding: '10px 60px',
    fontWeight: 600,
  },
}));

export const ReleaseNotesModal = () => {
  const dispatch = useDispatch();
  const setReleaseNotesRead = () => dispatch({
    type: 'RELEASE_NOTES',
    releaseNotes: {
      releaseNotes: {
        display: false,
        listType: 'none',
      },
    },
  });

  const { t } = useTranslation();
  const classes = useStyles();

  const releaseNotes = useSelector((state) => state.userInterface.releaseNotes);
  const open = releaseNotes && releaseNotes.display && displayUpdateNews(releaseNotes);
  const releaseNotesDisplay = availableReleaseNotes(releaseNotes);

  const closeModal = () => {
    setReleaseNotesRead();
    setLocalStorageReleaseNotesRead();
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeModal}
    >
      <DialogTitle>
        {releaseNotes && releaseNotes.type === 'latest' && t('alma.health.news')}
        {t('release.notes')}
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.intro}>{`${t('available.new.features')}:`}</Typography>
        <Grid container item spacing={2} xs={12} className={classes.content}>
          <ul>
            {releaseNotesDisplay.map((feature, key) => (
              <li key={key.toString()}>
                <Typography component="span">{`${feature.version}` }</Typography>
                <Typography component="span">
                  {` ${feature.date.day} ${t(`${feature.date.monthIndex}`)} ${feature.date.year}`}
                </Typography>
                {feature.news && feature.news.map((item, index) => (
                  <span key={index.toString()}>
                    <br />
                    {` - ${item}.`}
                  </span>
                ))}
              </li>
            ))}
          </ul>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          onClick={closeModal}
        >
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
