import React, { useContext } from 'react';

import '../i18n';
import '../styles/global.css';
import '../../node_modules/bootstrap-utilities/bootstrap-utilities.css';

import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { useQuery } from 'react-apollo';
import { navigate } from 'gatsby';
import { useKeycloak } from 'react-keycloak';

import Seo from './Seo';
import Viewer from './Viewer/Viewer';
import { AppContainer } from './Common/styled/AppContainer';
import GlobalStyle from '../themes/GlobalStyle';
import GlobalThemeStyles from '../themes/GlobalThemeStyles';
import { ToastContainer } from './Common/Toast/ToastContainer';
import Loading from './Common/Loading';
import { isEmpty } from '../utils/ObjectUtils';
import { StudyDownloadWidget } from './Widget/Download/StudyDownloadWidget';
import { StudyUploadWidget } from './Widget/Upload/StudyUploadWidget';

import { GET_HOSPITALS, GET_PROVIDERS } from '../graphql/queries';
import { getSessionStorageJson } from '../utils/ReadStorageUtils';
import { useApplicationInterface } from '../utils/ApplicationInterfaceUtils';
import { ImportStudyWidget } from './Widget/Import/ImportStudyWidget';

export default ({ children }) => {
  const dispatch = useDispatch();
  const { data: queryHospitalData } = useQuery(GET_HOSPITALS, {
    onCompleted: (d) => {
      sessionStorage.setItem('hospitalData', JSON.stringify(d));
    },
  });
  const { data: queryProviderData } = useQuery(GET_PROVIDERS, {
    onCompleted: (d) => {
      sessionStorage.setItem('providerData', JSON.stringify(d));
    },
  });

  const currentHospital = useSelector((state) => state.hospital);
  const currentProvider = useSelector((state) => state.provider);
  const themeContext = useContext(ThemeContext);
  const [keycloak] = useKeycloak();

  const { patientApp } = useApplicationInterface();
  const redirectDomain = patientApp ? process.env.GATSBY_APP_TELEMEDICINE_URL : process.env.GATSBY_APP_CLOUD_URL;

  if (!keycloak.authenticated) {
    keycloak.login({ redirectUri: `${redirectDomain}${window.location.pathname}${window.location.search}` });
    return <div>Loading...</div>;
  }

  if (!keycloak.tokenParsed.roles.includes('https://api.cloud.alma-medical.com/access')) {
    return <div>You don&apos;t have the permission to access the platform</div>;
  }

  if (!keycloak.tokenParsed.roles.includes('https://api.cloud.alma-medical.com/doctor')) {
    if (keycloak.tokenParsed.roles.includes('https://api.cloud.alma-medical.com/patient')) {
      window.location.href = process.env.GATSBY_APP_TELEMEDICINE_URL;
    }

    return <div>You don&apos;t have the permission to access the platform</div>;
  }

  const hospitalData = !isEmpty(queryHospitalData) ? queryHospitalData : getSessionStorageJson('hospitalData');
  const providerData = !isEmpty(queryProviderData) ? queryProviderData : getSessionStorageJson('providerData');

  if (isEmpty(hospitalData) || isEmpty(providerData)) {
    return <Loading />;
  }

  // If user has providers and none is currently set, set the first one as current
  const { edges: providers } = providerData.providers;
  if (providers && providers.length && !currentProvider) {
    const { node } = providers[0];
    dispatch({ type: 'CHANGE_PROVIDER', current: node });
  }

  // Set hospital for user from one of their own or redirect to /welcome to create one
  const { edges: hospitals } = hospitalData.hospitals;

  if (!hospitals && !currentHospital) {
    navigate('/welcome/');
    return null;
  }

  if (!hospitals.length && !currentHospital) {
    navigate('/welcome/');
    return null;
  }

  const bootstrapHospitalEnvironment = () => {
    if (!hospitals) return;
    if (!hospitals.length) return;
    if (!currentHospital) {
      const hospitalUuid = localStorage.getItem('hospitalUuid');
      const { node } = hospitalUuid && hospitals.find((h) => h.node.uuid === hospitalUuid)
        ? hospitals.find((h) => h.node.uuid === hospitalUuid)
        : hospitals[0];
      dispatch({ type: 'CHANGE_HOSPITAL', current: node });
      themeContext.setPrimaryColor(node.mainColor);
      return;
    }
    const hospitalUuidList = hospitals.map((edge) => edge.node && edge.node.uuid);
    if (hospitalUuidList.includes(currentHospital.uuid)) return;
    const { node } = hospitals[0];
    dispatch({ type: 'CHANGE_HOSPITAL', current: node });
    themeContext.setPrimaryColor(node.mainColor);
  }

  bootstrapHospitalEnvironment();

  if (currentHospital && currentHospital.mainColor) {
    themeContext.setPrimaryColor(currentHospital.mainColor);
  }

  return (
    <AppContainer>
      <Seo />
      <GlobalStyle />
      <GlobalThemeStyles />
      {children}
      <ToastContainer />
      <StudyUploadWidget />
      <StudyDownloadWidget />
      <ImportStudyWidget />
      <Viewer />
    </AppContainer>
  );
};
